import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import { createModalToken } from "@components/Modal/ModalManager";
import { LoggerInterface } from "@interfaces/LoggerInterface";

import ReviewsIconCollectionManager from "./utils/ReviewsIconCollectionManager";

export const ReviewsApplicationConfigToken = Symbol(
    "ReviewsApplicationConfigToken"
);

export const reviewsApplicationLoggerToken = Symbol(
    "ReviewsApplicationLoggerToken"
) as InjectionToken<LoggerInterface>;

export const reviewsAuthApiClientToken = Symbol(
    "reviewsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const reviewsTranslationsAuthApiClientToken = Symbol(
    "reviewsTranslationsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const LeaveReviewPageApplicationConfigToken = Symbol(
    "LeaveReviewPageApplicationConfigToken"
);

export const questionsFormModalToken = createModalToken(
    "questionsFormModalToken"
);

export const reviewsFormModalToken = createModalToken("reviewsFormModalToken");

export const reviewsGalleryModalToken = createModalToken(
    "reviewsGalleryModalToken"
);

export const reviewsIconCollectionManagerToken = Symbol(
    "reviewsIconCollectionManagerToken"
) as InjectionToken<ReviewsIconCollectionManager>;
